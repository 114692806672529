<template>
  <b-card>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Generalne"
        active
      >

        <b-form-group
          id="fieldset-horizontal2"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Imię"
          label-for="input-horizontal"
        >
          <b-form-input
            v-model="user.first_name"
            placeholder="Imię"
          />
        </b-form-group>

        <b-form-group
          id="fieldset-horizontal3"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Nazwisko"
          label-for="input-horizontal"
        >
          <b-form-input
            v-model="user.last_name"
            placeholder="Nazwisko"
          />
        </b-form-group>
        <b-form-group
          id="fieldset-horizontal4"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Nick"
          label-for="input-horizontal"
        >
          <b-form-input
            v-model="user.nick"
            placeholder="Nick"
          />
        </b-form-group>

        <b-form-group
          id="fieldset-horizontal5"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Hasło"
          label-for="input-horizontal"
        >
          <b-form-input
            v-model="user.password"
            type="password"
            placeholder="Hasło"
          />
        </b-form-group>

        <b-form-group
          id="fieldset-horizontal6"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Zdjęcie profilowe"
          label-for="input-horizontal"
        >
          <b-form-file
            browse-text="Wybierz"
            placeholder="Wybierz plik"
          />
        </b-form-group>

        <b-form-group id="fieldset-horizontal7">
          <b-button
            variant="success"
            @click="saveData()"
          >
            Zapisz
          </b-button>

          <b-button
            v-b-modal.delete-account-modal
            variant="danger"
            class="mx-1"
          >
            Usuń konto
          </b-button>

          <b-modal
            id="delete-account-modal"
            :cancel-disabled="true"
            :ok-disabled="true"
          >
            <template>
              <label for="password">Wpisz hasło przed usunięciem konta:</label>
              <b-form-input
                id="password"
                v-model="user.confirm_password"
                type="password"
                placeholder="Hasło:"
                @input.native="clearInvalid($event)"
              />
            </template>
            <!-- delete modal footer -->
            <template
              #modal-footer
            >
              <b-button
                v-b-modal.delete-account-modal
                variant="danger"
                class="mx-1"
                @click="deleteUser()"
              >
                Delete
              </b-button>
            </template>
          </b-modal>
        </b-form-group>

      </b-tab>
    </b-tabs>

  </b-card>
</template>

<script>
import {
  BCard, BFormInput, BFormGroup, BTab, BTabs, BFormFile, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BFormInput,
    BFormFile,
    BFormGroup,
    BTab,
    BTabs,
    BButton,
  },
  data() {
    return {
      user: {
        name: '',
        first_name: '',
        last_name: '',
        nick: '',
        avatar: '',
        password: '',
        confirm_password: '',
      },
    }
  },
  created() {
    this.fetchMyData()
  },
  methods: {
    async deleteUser() {
      // password input box
      const passwordInput = document.querySelector('#password')
      // data to send
      const data = {
        password: this.user.confirm_password,
      }

      // make a call
      this.$http.post(`https://api.${this.$store.state.domain}/api/me/delete`, data)
      // this.dummyAPI(data)
      // if delete successful
        .then(() => {
          // logout and go to login page
          useJwt.logout()
          this.$router.push({ name: 'login' })
        })
      // if not
        .catch(() => {
          // error toast
          this.$bvToast.toast('password is incorrect', {
            title: 'password',
            variant: 'danger',
          })
          // make input red
          if (!passwordInput.classList.contains('is-invalid')) passwordInput.classList.add('is-invalid')
        })
    },
    // dummy user deletion API
    dummyAPI(data) {
      return new Promise((resolve, reject) => {
        if (data.password === 'admin') {
          resolve('deletion successful')
        } else {
          reject('wrong password')
        }
      })
    },
    // removes red outline on input
    clearInvalid(event) {
      if (event.currentTarget.classList.contains('is-invalid')) event.currentTarget.classList.remove('is-invalid')
    },
    fetchMyData() {
      this.$http.get(`https://api.${this.$store.state.domain}/api/me`).then(response => {
        console.log('MY', response.data)
        this.user = response.data.user
        this.user.first_name = this.user.name.split(' ')[0] ?? ''
        this.user.last_name = this.user.name.split(' ')[1] ?? ''
      })
    },
    saveData() {
      const data = this.user
      if (data.password === '') {
        delete data.password
      }
      data.name = `${data.first_name} ${data.last_name}`
      this.$http.post(`https://api.${this.$store.state.domain}/api/me/update`, data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Dane zapisane pomyślnie', {
            title: 'Zapis',
            variant: 'success',
          })
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas zapisu', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
